// HomePage.js
import React from 'react';
import ImpressumHero from './ImpressumHero';


function Impressum() {
  return (
    <div>
      <ImpressumHero />
    </div>
  );
}

export default Impressum;
