import React, { useState } from 'react';
import { Container, Grid, Box, Typography, TextField, Button, Snackbar, Alert } from '@mui/material';
import { styled } from '@mui/system';
import axios from 'axios';

const StyledForm = styled(Box)(({ theme }) => ({
  padding: theme.spacing(4),
  backgroundColor: 'transparent',
  borderRadius: '25px',
  border: '2px solid black', // Added for thicker borders
}));

const StyledTextField = styled(TextField)({
  '& .MuiOutlinedInput-root': {
    borderRadius: '25px', // Rounded borders
    borderWidth: '2px', // Thicker borders
    '& fieldset': {
      borderColor: 'black',
      borderWidth: '2px', // Thicker borders
    },
    '&:hover fieldset': {
      borderColor: 'black',
    },
    '&.Mui-focused fieldset': {
      borderColor: 'black',
    },
  },
  '& .MuiInputBase-input': {
    color: 'black', // Ensuring the input text color is black
  },
  '& .MuiInputLabel-root': {
    color: 'black', // Ensuring the label text color is black
  },
});

const ContactForm = () => {
  const backgroundImage = `${process.env.PUBLIC_URL}/images/bright_background.jpg`;

  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: '',
  });

  const [alertOpen, setAlertOpen] = useState(false);
  const [alertSeverity, setAlertSeverity] = useState('success');
  const [alertMessage, setAlertMessage] = useState('');

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    axios.post('/api/sendMessage', formData)
      .then(response => {
        setAlertMessage('Message sent successfully!');
        setAlertSeverity('success');
        setAlertOpen(true);
      })
      .catch(error => {
        setAlertMessage('Error sending message.');
        setAlertSeverity('error');
        setAlertOpen(true);
      });
  };

  const handleAlertClose = () => {
    setAlertOpen(false);
  };

  return (
    <Container
      maxWidth="false"
      sx={{
        backgroundImage: `url(${backgroundImage})`,
        backgroundSize: 'cover',
        backgroundPosition: 'left',
        py: 8,
        color: 'black',
        zIndex: 1,
        pt: 8,
        pb: 8
      }}
    >
      <Container>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <Typography variant="h2" align="left" gutterBottom sx={{ fontWeight: 500 }}>
              Ihre digitale Reise beginnt hier
            </Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <StyledForm component="form" onSubmit={handleSubmit}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <StyledTextField
                    fullWidth
                    label="Name"
                    name="name"
                    value={formData.name}
                    onChange={handleChange}
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <StyledTextField
                    fullWidth
                    label="E-Mail"
                    name="email"
                    value={formData.email}
                    onChange={handleChange}
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={12}>
                  <StyledTextField
                    fullWidth
                    label="Nachricht"
                    name="message"
                    value={formData.message}
                    onChange={handleChange}
                    multiline
                    rows={4}
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={12}>
                  <Button type="submit" variant="contained" color="primary">
                    Senden
                  </Button>
                </Grid>
              </Grid>
            </StyledForm>
          </Grid>
        </Grid>
      </Container>
      <Snackbar open={alertOpen} autoHideDuration={6000} onClose={handleAlertClose}>
        <Alert onClose={handleAlertClose} severity={alertSeverity} sx={{ width: '100%' }}>
          {alertMessage}
        </Alert>
      </Snackbar>
    </Container>
  );
};

export default ContactForm;
