import React from 'react';
import { Container, Typography, Box, Link, Grid, IconButton, Button } from '@mui/material';
import { styled } from '@mui/material/styles';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import LinkedInIcon from '@mui/icons-material/LinkedIn';

const FooterBackground = styled(Box)(({ theme }) => ({
  backgroundColor: 'black',
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  zIndex: -2, // Ensure it is behind the footer container
}));

const FooterContainer = styled(Box)(({ theme }) => ({
  backgroundColor: 'transparent',
  color: '#ffffff',
  padding: theme.spacing(8, 0),
  textAlign: 'center',
  position: 'relative',
  [theme.breakpoints.down('sm')]: {
    padding: theme.spacing(4, 0),
  },
}));

const FooterLinks = styled(Link)(({ theme }) => ({
  margin: theme.spacing(0, 1),
  color: '#ffffff',
  '&:hover': {
    color: theme.palette.primary.main,
  },
  [theme.breakpoints.down('sm')]: {
    margin: theme.spacing(0.5, 0.5),
  },
}));

const FooterBottom = styled(Box)(({ theme }) => ({
  marginTop: theme.spacing(4),
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  textAlign: 'center',
  flexWrap: 'wrap',
  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
  },
}));

const FooterLinkGroup = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  marginTop: theme.spacing(4),
  flexWrap: 'wrap',
  [theme.breakpoints.down('sm')]: {
    marginTop: theme.spacing(2),
  },
}));

const FooterCard = styled(Box)(({ theme }) => ({
  backgroundColor: 'grey',
  opacity: 0.9,
  padding: theme.spacing(4),
  borderRadius: '25px',
  display: 'inline-block',
  textAlign: 'center',
  height: theme.spacing(40),
  width: '60%',
  flexWrap: 'wrap',
  [theme.breakpoints.down('sm')]: {
    width: '80%',
    height: 'auto',
    padding: theme.spacing(2),
  },
}));

const Footer = () => {
  return (
    <Box position="relative">
      <FooterBackground />
      <FooterContainer>
        <Container maxWidth="lg">
          <FooterCard>
            <Typography sx={{ mt: 5 }} variant="h2" gutterBottom>
              Let's Work Together
            </Typography>
            <Typography variant="subtitle1" gutterBottom>
              Steigern Sie Ihre Marke. Klicken Sie für digitale Exzellenz!
            </Typography>
            <Box mt={5}>
              <Button variant="contained" color="primary" href="/contact" sx={{ fontSize: '16px', borderRadius: '25px' }}>
                Let's Chat
              </Button>
            </Box>
          </FooterCard>
          <Grid container sx={{ mt: 3 }} spacing={2} component={FooterLinkGroup}>
            <Grid item>
              <FooterLinks href="/home">Home</FooterLinks>
            </Grid>
            <Grid item>
              <FooterLinks href="/contact">Kontakt</FooterLinks>
            </Grid>
            <Grid item>
              <FooterLinks href="/impressum">Impressum</FooterLinks>
            </Grid>
          </Grid>
          <FooterBottom>
            <Typography variant="body2">&copy; 2024 MJ Webagentur. All Rights Reserved.</Typography>
          </FooterBottom>
        </Container>
      </FooterContainer>
    </Box>
  );
};

export default Footer;
