// HomePage.js
import React from 'react';
import ContactHero from './ContactHero';
import ContactForm from './ContactForm';
import FAQ from './FAQ';

function Contact() {
  return (
    <div>
        <ContactHero />
        <ContactForm />
        <FAQ />
    </div>
  );
}

export default Contact;
