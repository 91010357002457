import React from 'react';
import { Container, Grid, Typography, Card, CardContent, useMediaQuery, useTheme } from '@mui/material'; // Added useMediaQuery and useTheme
import WebIcon from '@mui/icons-material/Web';
import SeoIcon from '@mui/icons-material/Search';
import UxUiIcon from '@mui/icons-material/DesignServices';

const services = [
  {
    title: 'WEB DESIGN',
    description: 'Von Unternehmensseiten bis hin zu E-Commerce-Plattformen erstellen wir atemberaubende Websites, die fesseln und konvertieren.',
    icon: <WebIcon style={{ fontSize: 120 }} />, // Schwarz-Weiß-Icon
  },
  {
    title: 'SEO',
    description: 'Wir optimieren Ihre Website, um höher zu ranken, mehr organischen Traffic zu generieren und qualifizierte Leads anzuziehen.',
    icon: <SeoIcon style={{ fontSize: 120 }} />, // Schwarz-Weiß-Icon
  },
  {
    title: 'UX/UI DESIGN',
    description: 'Vom Entwurf bis zum Prototyping konzentrieren wir uns auf benutzerzentriertes Design, um Ihre digitale Präsenz zu verbessern.',
    icon: <UxUiIcon style={{ fontSize: 120 }} />, // Schwarz-Weiß-Icon
  },
];

const ServicesSection = () => {
  const theme = useTheme(); // Added for responsive design
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm')); // Added for responsive design
  const backgroundImage = `${process.env.PUBLIC_URL}/images/bright_background.jpg`;

  return (
    <Container
      maxWidth={false}
      sx={{
        backgroundImage: `url(${backgroundImage})`,
        backgroundSize: 'cover',
        backgroundPosition: 'left',
        py: 8,
        color: 'black',
        zIndex: 1,
        pt: 5,
        pb: 5,
        mt: 0,
        mb: 0
      }}
    >
      <Container sx={{ mt: 5, mb: 5 }}>
        <Grid container spacing={isSmallScreen ? 4 : 6} justifyContent="left" alignItems="center"> {/* Adjusted spacing for responsiveness */}
          <Grid item xs={12} md={4}>
            <Typography variant={isSmallScreen ? "h4" : "h3"} gutterBottom align="left" sx={{ fontWeight: 1000 }}> {/* Changed for responsive design */}
              Unsere Leistungen
            </Typography>
          </Grid>
          <Grid item xs={12} md={8}>
            <Typography variant={isSmallScreen ? "body1" : "h6"} gutterBottom align="left" sx={{ mb: 4, fontWeight: 500, alignItems: 'right' }}> {/* Changed for responsive design */}
              Tauchen Sie ein in unser Fachwissen in den Bereichen Nutzererfahrungsoptimierung, digitale Strategie und modernste Technologie.
            </Typography>
          </Grid>
        </Grid>
        <Grid container spacing={4} justifyContent="left">
          {services.map((service, index) => (
            <Grid item xs={12} sm={6} md={4} key={index}>
              <Card sx={{ backgroundColor: '#fafafa', color: "black", height: '100%', borderRadius: '25px' }}>
                <CardContent sx={{ p: isSmallScreen ? 3 : 6 }}> {/* Changed for responsive design */}
                  <Typography variant="h4" gutterBottom align="left" sx={{ pb: isSmallScreen ? 5 : 15 }}> {/* Changed for responsive design */}
                    {service.icon}
                  </Typography>
                  <Typography variant="h5" gutterBottom align="left" sx={{ fontWeight: "1000" }}> {/* Changed for responsive design */}
                    {service.title}
                  </Typography>
                  <Typography variant="body1" align="left">
                    {service.description}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Container>
    </Container>
  );
};

export default ServicesSection;
