import React from 'react';
import { Container, Grid, Box, Typography, Link, useMediaQuery, useTheme } from '@mui/material'; // Added useMediaQuery and useTheme
import { styled } from '@mui/system';

const projects = [
  {
    title: 'Insights',
    description: 'Kundenfeedbackanalyse Tool',
    image: `${process.env.PUBLIC_URL}/images/insights.png`,
    link: 'https://insights-ai.net',
  },
  {
    title: 'Samusew Clean-Life',
    description: 'Garten & Gebäudemanagement',
    image: `${process.env.PUBLIC_URL}/images/image.png`,
    link: 'https://samusew-clean-life.de',
  },
  {
    title: 'DAD-Service',
    description: 'Elektro- und Gebäudetechnik',
    image: `${process.env.PUBLIC_URL}/images/dadservice.png`,
    link: 'https://dad-service.de',
  },
];

const ProjectItem = styled(Box)(({ theme }) => ({
  position: 'relative',
  overflow: 'hidden',
  cursor: 'pointer',
  borderRadius: '25px',
  '&:hover .overlay': {
    opacity: 1,
  },
  '&:hover .title': {
    opacity: 1,
    transform: 'translateY(0)',
  },
  '&:hover .subtitle': {
    opacity: 1,
    transform: 'translateY(0)',
  },
}));

const Overlay = styled(Box)(({ theme }) => ({
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  backgroundColor: 'rgba(0, 0, 0, 0.5)',
  opacity: 0,
  transition: 'opacity 0.5s ease-in-out',
}));

const Title = styled(Typography)(({ theme }) => ({
  position: 'absolute',
  bottom: '50px',
  left: '20px',
  color: '#fff',
  fontSize: '24px',
  opacity: 0,
  transform: 'translateY(20px)',
  transition: 'opacity 0.5s ease-in-out, transform 0.5s ease-in-out',
  [theme.breakpoints.down('sm')]: { // Added for responsive design
    fontSize: '18px', // Adjusted for small screens
    bottom: '30px', // Adjusted for small screens
    left: '10px', // Adjusted for small screens
  },
}));

const Subtitle = styled(Typography)(({ theme }) => ({
  position: 'absolute',
  bottom: '20px',
  left: '20px',
  color: '#fff',
  fontSize: '16px',
  opacity: 0,
  transform: 'translateY(20px)',
  transition: 'opacity 0.5s ease-in-out, transform 0.5s ease-in-out',
  [theme.breakpoints.down('sm')]: { // Added for responsive design
    fontSize: '12px', // Adjusted for small screens
    bottom: '10px', // Adjusted for small screens
    left: '10px', // Adjusted for small screens
  },
}));

const RecentProjects = () => {
  const theme = useTheme(); // Added for responsive design
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm')); // Added for responsive design
  
  return (
    <Container sx={{ pt: 10, pb: 10, backgroundColor: "transparent"}}>
      <Box sx={{ padding: isSmallScreen ? '20px' : '40px', backgroundColor: 'transparent', color: '#fff', borderRadius: '25px' }}> {/* Changed for responsive design */}
        <Typography variant={isSmallScreen ? "h4" : "h3"} sx={{ marginBottom: '20px', fontWeight: 1000 }}> {/* Changed for responsive design */}
          Vergangene Projekte
        </Typography>
        <Grid container spacing={4}>
          {projects.map((project, index) => (
            <Grid item xs={12} sm={6} md={6} key={index}>
              <Link href={project.link} target="_blank" underline="none">
                <ProjectItem
                  sx={{
                    backgroundImage: `url(${project.image})`,
                    backgroundSize: 'cover',
                    backgroundRepeat: 'no-repeat',
                    backgroundPosition: 'top',
                    borderRadius: '25px',
                    height: isSmallScreen ? '200px' : '300px', // Changed for responsive design
                  }}
                >
                  <Overlay className="overlay" />
                  <Title className="title">{project.title}</Title>
                  <Subtitle className="subtitle">{project.description}</Subtitle>
                </ProjectItem>
              </Link>
            </Grid>
          ))}
        </Grid>
      </Box>
    </Container>
  );
};

export default RecentProjects;
