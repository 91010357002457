// src/theme.js
import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    // Define your color scheme here
    primary: {
      main: '#000000', // Example blue similar to Apple's primary blue
    },
    secondary: {
      main: '#000000', // Example red for contrast actions
    },
    background: {
      default: '#000000', // Light grey background for a clean look
    },
    text: {
      primary: '#ffffff', // Dark grey for text for better readability
      secondary: '#ffffff', // Lighter grey for secondary text
    },
  },
  typography: {
    fontFamily: 'Montserrat, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif',
    h1: {
      fontWeight: 400,
    },
    h2: {
      fontWeight: 400,
    },
    // Continue with other styles...
  },
  components: {
    // You can customize MUI components globally here
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'none', // Buttons with regular case text, not uppercase
          borderRadius: 8, // Rounded corners for buttons
          backgroundColor: 'black',
        },
      },
    },
    // Add more component customizations here
  },
});

export default theme;
