import React, { useEffect, useMemo, useState } from "react";
import Particles, { initParticlesEngine } from "@tsparticles/react";
import { loadSlim } from "@tsparticles/slim";
import { Container, Typography, useMediaQuery, useTheme } from '@mui/material';
import { useSpring, animated } from '@react-spring/web';

const ContactHero = () => {
  const [init, setInit] = useState(false);
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(() => {
    initParticlesEngine(async (engine) => {
      await loadSlim(engine);
    }).then(() => {
      setInit(true);
    });
  }, []);

  const particlesLoaded = (container) => {
    console.log(container);
  };

  const options = useMemo(
    () => ({
      background: {
        color: {
          value: "#000000",
        },
      },
      fpsLimit: 30,
      interactivity: {
        events: {
          onClick: {
            enable: true,
            mode: "push",
          },
          onHover: {
            enable: true,
            mode: "repulse",
          },
        },
        modes: {
          push: {
            quantity: 6,
          },
          repulse: {
            distance: 400,
            duration: 0.4,
          },
        },
      },
      particles: {
        color: {
          value: "#ffffff",
        },
        links: {
          color: "#ffffff",
          distance: 150,
          enable: true,
          opacity: 0.5,
          width: 2,
        },
        move: {
          direction: "none",
          enable: true,
          outModes: {
            default: "bounce",
          },
          random: false,
          speed: 2,
          straight: false,
        },
        number: {
          density: {
            enable: true,
          },
          value: 80,
        },
        opacity: {
          value: 0.5,
        },
        shape: {
          type: "circle",
        },
        size: {
          value: { min: 1, max: 5 },
        },
      },
      detectRetina: true,
    }),
    []
  );

  const headingSpring = useSpring({
    opacity: init ? 1 : 0,
    delay: 1000,
    config: { duration: 2000 },
  });

  const subheadingSpring = useSpring({
    opacity: init ? 1 : 0,
    delay: 1500,
    config: { duration: 2000 },
  });

  const nonSelectableStyle = {
    userSelect: 'none',
    WebkitUserSelect: 'none',
    MozUserSelect: 'none',
    msUserSelect: 'none',
  };

  return (
    <animated.div style={{ height: '120vh', width: '100vw', backgroundColor: 'transparent', position: 'relative' }}>
      <div style={{ position: 'fixed', top: 0, left: 0, width: '100%', height: '100%', zIndex: -1 }}>
        {init && (
          <Particles
            id="tsparticles"
            particlesLoaded={particlesLoaded}
            options={options}
            style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%' }}
          />
        )}
      </div>
      <Container
        maxWidth={false}
        sx={{
          p: 4,
          mt: 1,
          mb: 1,
          position: 'relative',
          height: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          zIndex: 1,
        }}
      >
        <animated.div style={{ textAlign: 'center', ...headingSpring }}>
          <img
            src={`${process.env.PUBLIC_URL}/images/Firmenlogo_weiss.png`}
            alt="Logo"
            style={{ width: isSmallScreen ? '125px' : '250px', ...nonSelectableStyle }}
          />
          <Typography
            color="white"
            variant={isSmallScreen ? 'h3' : 'h2'}
            gutterBottom
            sx={{ ...nonSelectableStyle }}
          >
            Kontaktieren Sie uns!
          </Typography>
          <animated.div style={{ ...subheadingSpring }}>
            <Typography sx={{ ...nonSelectableStyle }} color="white" variant={isSmallScreen ? 'h5' : 'h4'}>
              Wir sind bereit, mit Ihnen in Kontakt zu treten, Ihre Fragen zu beantworten und ein Gespräch über Ihre digitalen Ziele zu beginnen.
            </Typography>
          </animated.div>
        </animated.div>
      </Container>
    </animated.div>
  );
};

export default ContactHero;
