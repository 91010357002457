import React from 'react';
import { Container, Grid, Box, Typography, Avatar, IconButton, Button } from '@mui/material';
import { styled } from '@mui/system';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import MailIcon from '@mui/icons-material/Mail';

const teamMembers = [
  {
    name: 'Marc Herlitz',
    role: 'Leiter Webdesign',
    image: `${process.env.PUBLIC_URL}/images/marc.jpg`, // replace with actual image path
    record: 'Bachelor of Engineering',
    mail: 'marc@mj-webagentu.de',
    linkedin: 'https://www.linkedin.com/in/marcherlitz',
  },
  {
    name: 'Jonas  Jörns',
    role: 'Leiter Geschäftsentwicklung',
    image: `${process.env.PUBLIC_URL}/images/jonas.jpg`, // replace with actual image path
    record: 'Bachelor of Engineering',
    mail: 'jonas@mj-webagentu.de',
    linkedin: 'https://www.linkedin.com/in/jonas-j%C3%B6rns-9b7368211',
  }
];

const MemberCard = styled(Box)(({ theme }) => ({
  textAlign: 'center',
  padding: theme.spacing(2),
  backgroundColor: '#1c1c1c',
  color: '#fff',
  borderRadius: '25px',
  transition: 'transform 0.3s ease-in-out',
  '&:hover': {
    transform: 'scale(1.05)',
  },
}));

const MemberAvatar = styled(Avatar)(({ theme }) => ({
  width: theme.spacing(20),
  height: theme.spacing(20),
  margin: 'auto',
  marginBottom: theme.spacing(2),
  borderRadius: '25px',
}));

const SocialIcons = styled(Box)(({ theme }) => ({
  marginTop: theme.spacing(1),
  '& a': {
    color: '#fff',
    margin: theme.spacing(0.5),
  },
}));

const OurTeam = () => {
  const backgroundImage = `${process.env.PUBLIC_URL}/images/bright_background.jpg`;
  return (
    <Container maxWidth='false' sx={{ 
        backgroundImage: `url(${backgroundImage})`,
        backgroundSize: 'cover',
        backgroundPosition: 'left',
        py: 8,
        color: 'black',
        zIndex: 1,
        pt: 10,
        pb: 10,
     }}>
    <Container maxWidth='lg'>
        <Typography variant="h2" align="center" gutterBottom sx={{fontWeight: 1000}}>
            Unser Team
        </Typography>
        <Grid container spacing={4} justifyContent="center">
            {teamMembers.map((member, index) => (
            <Grid item xs={12} sm={6} md={4} key={index}>
                <MemberCard>
                <MemberAvatar src={member.image} alt={member.name} sx={{mt: 5, mb: 5}}/>
                <Typography variant="h6">{member.name}</Typography>
                <Typography variant="body1">{member.record}</Typography>
                <Typography variant="body1">{member.role}</Typography>
                <SocialIcons>
                    <IconButton component="a" href={member.linkedin} target="_blank">
                    <LinkedInIcon />
                    </IconButton>
                    <IconButton component="a" href={member.mail} target="_blank">
                    <MailIcon />
                    </IconButton>
                </SocialIcons>
                </MemberCard>
            </Grid>
            ))}
        </Grid>
        {/* <Box textAlign="center" mt={4}>
            <Button sx={{ borderRadius: '25px' }} variant="contained" color="primary">
            Contact Us
            </Button>
        </Box> */}
    </Container>
    </Container>
  );
};

export default OurTeam;
