// HomePage.js
import React from 'react';
import Model from './Model';
import ServicesSection from './ServicesSection';
import RecentProjects from './RecentProjects';
import OurTeam from './OurTeam';
import FAQ from './FAQ';

function HomePage() {
  return (
    <div>
      <Model />
      <ServicesSection />
      <RecentProjects />
      <OurTeam />
      <FAQ />
    </div>
  );
}

export default HomePage;
