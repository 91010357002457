import React, { useEffect, useMemo, useState } from "react";
import Particles, { initParticlesEngine } from "@tsparticles/react";
import { loadSlim } from "@tsparticles/slim";
import { Container, Typography, Grid, Box, useMediaQuery, useTheme } from '@mui/material'; // Added useMediaQuery and useTheme
import { useSpring, animated } from '@react-spring/web';

const ImpressumHero = () => {
  const [init, setInit] = useState(false);
  const theme = useTheme(); // Added for responsive design
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm')); // Added for responsive design

  useEffect(() => {
    initParticlesEngine(async (engine) => {
      await loadSlim(engine);
    }).then(() => {
      setInit(true);
    });
  }, []);

  const options = useMemo(() => ({
    background: { color: { value: "#000000" } },
    fpsLimit: 30,
    interactivity: {
      events: { onClick: { enable: true, mode: "push" }, onHover: { enable: true, mode: "repulse" } },
      modes: { push: { quantity: 6 }, repulse: { distance: 400, duration: 0.4 } },
    },
    particles: {
      color: { value: "#ffffff" },
      links: { color: "#ffffff", distance: 150, enable: true, opacity: 0.5, width: 2 },
      move: { direction: "none", enable: true, outModes: { default: "bounce" }, random: false, speed: 2, straight: false },
      number: { density: { enable: true }, value: 80 },
      opacity: { value: 0.5 },
      shape: { type: "circle" },
      size: { value: { min: 1, max: 5 } },
    },
    detectRetina: true,
  }), []);

  const headingSpring = useSpring({ opacity: init ? 1 : 0, delay: 1000, config: { duration: 2000 } });

  return (
    <animated.div style={{ minHeight: '100vh', backgroundColor: 'transparent' }}>
      <Container
        maxWidth="lg"
        sx={{
          borderRadius: '10px',
          p: 4,
          mt: 0,
          mb: 0,
          overflow: 'hidden',
          position: 'relative',
          backgroundColor: 'transparent',
          minHeight: '100vh',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          zIndex: -1,
          color: 'white'
        }}
      >
        {init && (
          <animated.div
            style={{
              position: 'absolute',
              overflow: 'hidden',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
              zIndex: -1,
            }}
          >
            <Particles
              id="tsparticles"
              options={options}
              style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%' }}
            />
          </animated.div>
        )}
        <animated.div style={{ position: 'relative', zIndex: 1, textAlign: 'center', ...headingSpring }}>
          <Grid container spacing={4}>
            <Grid item xs={12} md={12} sx={{ mt: 5, mb: 5 }}> {/* Adjusted margins for responsiveness */}
              <Box
                sx={{
                  p: isSmallScreen ? 2 : 5, // Changed for responsive design
                  borderRadius: '25px',
                  border: '1px solid white',
                  backgroundColor: 'rgba(0, 0, 0, 0.6)',
                  height: '100%',
                }}
              >
                <Typography variant={isSmallScreen ? "h4" : "h2"} gutterBottom> {/* Changed for responsive design */}
                  Impressum
                </Typography>
                <div>
                  <p>
                    Angaben gemäß § 5 TMG
                  </p>
                  <p>
                    MJ Webagentur<br />
                    Herlitz & Jörns GbR
                    Bauerstraße 38<br />
                    80796 München<br />
                    Deutschland
                  </p>
                  <h2>Kontakt:</h2>
                  <p>
                    E-Mail: info@mj-webagentur.de
                  </p>
                  <h2>Haftungsausschluss:</h2>
                  <h3>Haftung für Inhalte</h3>
                  <p>
                    Als Diensteanbieter sind wir gemäß § 7 Abs.1 TMG für eigene Inhalte auf diesen Seiten nach den allgemeinen Gesetzen verantwortlich. Nach §§ 8 bis 10 TMG sind wir als Diensteanbieter jedoch nicht verpflichtet, übermittelte oder gespeicherte fremde Informationen zu überwachen oder nach Umständen zu forschen, die auf eine rechtswidrige Tätigkeit hinweisen.
                  </p>
                  <h3>Haftung für Links</h3>
                  <p>
                    Unser Angebot enthält Links zu externen Webseiten Dritter, auf deren Inhalte wir keinen Einfluss haben. Deshalb können wir für diese fremden Inhalte auch keine Gewähr übernehmen. Für die Inhalte der verlinkten Seiten ist stets der jeweilige Anbieter oder Betreiber der Seiten verantwortlich.
                  </p>
                </div>
              </Box>
            </Grid>
            <Grid item xs={12} md={12} sx={{ mt: 5, mb: 5 }}> {/* Adjusted margins for responsiveness */}
              <Box
                sx={{
                  p: isSmallScreen ? 2 : 5, // Changed for responsive design
                  borderRadius: '25px',
                  border: '1px solid white',
                  backgroundColor: 'rgba(0, 0, 0, 0.6)',
                  height: '100%',
                }}
              >
                <Typography variant={isSmallScreen ? "h4" : "h2"} gutterBottom> {/* Changed for responsive design */}
                  Datenschutzerklärung
                </Typography>
                <div>
                  <p>
                    Wir freuen uns sehr über Ihr Interesse an unserem Unternehmen. Datenschutz hat einen besonders hohen Stellenwert für die Geschäftsleitung der MJ Webagentur. Eine Nutzung der Internetseiten der MJ Webagentur ist grundsätzlich ohne jede Angabe personenbezogener Daten möglich. Sofern eine betroffene Person besondere Services unseres Unternehmens über unsere Internetseite in Anspruch nehmen möchte, könnte jedoch eine Verarbeitung personenbezogener Daten erforderlich werden.
                  </p>
                  <p>
                    Ist die Verarbeitung personenbezogener Daten erforderlich und besteht für eine solche Verarbeitung keine gesetzliche Grundlage, holen wir generell eine Einwilligung der betroffenen Person ein. Die Verarbeitung personenbezogener Daten, beispielsweise des Namens, der Anschrift, E-Mail-Adresse oder Telefonnummer einer betroffenen Person, erfolgt stets im Einklang mit der Datenschutz-Grundverordnung und in Übereinstimmung mit den für die MJ Webagentur geltenden landesspezifischen Datenschutzbestimmungen.
                  </p>
                  <h2>1. Name und Anschrift des für die Verarbeitung Verantwortlichen</h2>
                  <p>
                    Verantwortlicher im Sinne der Datenschutz-Grundverordnung, sonstiger in den Mitgliedstaaten der Europäischen Union geltenden Datenschutzgesetze und anderer Bestimmungen mit datenschutzrechtlichem Charakter ist die:
                  </p>
                  <p>
                    MJ Webagentur<br />
                    Herlitz & Jörns GbR
                    Bauerstraße 38<br />
                    80796 München<br />
                    Deutschland<br />
                    E-Mail: info@mjwebagentur.de<br />
                    Website: <a href="http://www.mj-webagentur.de" style={{ color: "white" }}>www.mj-webagentur.de</a>
                  </p>
                  <h2>2. Erfassung von allgemeinen Daten und Informationen</h2>
                  <p>
                    Die Internetseite der MJ Webagentur erfasst mit jedem Aufruf der Internetseite durch eine betroffene Person oder ein automatisiertes System eine Reihe von allgemeinen Daten und Informationen. Diese allgemeinen Daten und Informationen werden in den Logfiles des Servers gespeichert. Erfasst werden können die (1) verwendeten Browsertypen und Versionen, (2) das vom zugreifenden System verwendete Betriebssystem, (3) die Internetseite, von welcher ein zugreifendes System auf unsere Internetseite gelangt (sogenannte Referrer), (4) die Unterwebseiten, welche über ein zugreifendes System auf unserer Internetseite angesteuert werden, (5) das Datum und die Uhrzeit eines Zugriffs auf die Internetseite, (6) eine Internet-Protokoll-Adresse (IP-Adresse), (7) der Internet-Service-Provider des zugreifenden Systems und (8) sonstige ähnliche Daten und Informationen, die der Gefahrenabwehr im Falle von Angriffen auf unsere informationstechnologischen Systeme dienen.
                  </p>
                </div>
              </Box>
            </Grid>
          </Grid>
        </animated.div>
      </Container>
    </animated.div>
  );
};

export default ImpressumHero;
