import React from 'react';
import { Container, Typography, Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { styled } from '@mui/system';

const faqs = [
    {
      question: 'Wie lange dauert es, eine Website zu erstellen?',
      answer: 'Die Dauer der Erstellung einer Website hängt von der Komplexität und den individuellen Anforderungen des Projekts ab. In der Regel dauert es zwischen 4 und 12 Wochen. Einfachere Websites können schneller erstellt werden, während umfangreichere Projekte mehr Zeit in Anspruch nehmen können. Wir arbeiten eng mit unseren Kunden zusammen, um sicherzustellen, dass alle Wünsche und Anforderungen termingerecht erfüllt werden.',
    },
    {
      question: 'Welche digitalen Marketingstrategien bieten Sie an?',
      answer: 'Wir bieten eine Vielzahl von digitalen Marketingstrategien an, um Ihre Online-Präsenz zu stärken und Ihre Zielgruppe effektiv zu erreichen. Dazu gehören Suchmaschinenoptimierung (SEO), Social Media Marketing, Content Marketing, Pay-per-Click-Werbung (PPC) und E-Mail-Marketing. Unser Ziel ist es, maßgeschneiderte Strategien zu entwickeln, die auf Ihre speziellen Bedürfnisse und Geschäftsziele abgestimmt sind.',
    },
    {
      question: 'Kann ich Beispiele Ihrer bisherigen Arbeiten sehen?',
      answer: 'Ja, wir haben ein umfangreiches Portfolio mit Beispielen unserer bisherigen Arbeiten. Darin finden Sie verschiedene Projekte aus unterschiedlichen Branchen, die unsere Vielseitigkeit und unser Können demonstrieren. Kontaktieren Sie uns, um Zugang zu unserem Portfolio zu erhalten und mehr über unsere erfolgreich umgesetzten Projekte zu erfahren.',
    },
    {
      question: 'Wie gewährleisten Sie die Sicherheit meiner Website?',
      answer: 'Die Sicherheit Ihrer Website hat für uns oberste Priorität. Wir implementieren modernste Sicherheitsmaßnahmen, wie SSL-Zertifikate für sichere Datenübertragungen, regelmäßige Software-Updates und umfassende Sicherheitsüberprüfungen. Darüber hinaus setzen wir sichere Softwarelösungen ein, um Datenschutzrichtlinien und Impressum rechtssicher zu erstellen und zu verwalten. Dadurch stellen wir sicher, dass Ihre Website nicht nur optisch ansprechend, sondern auch sicher und rechtskonform ist.',
    },
    {
      question: 'Was unterscheidet Ihre Agentur von anderen?',
      answer: 'Unsere Agentur zeichnet sich durch unsere Flexibilität und unser Engagement für Qualität aus. Wir bieten maßgeschneiderte Lösungen, die exakt auf die Bedürfnisse unserer Kunden zugeschnitten sind. Zu unseren weiteren Qualitäten gehören das professionelle Aufsetzen von Logos und Designs, die Erstellung und Verwaltung von Google Business Einträgen sowie die rechtssichere Erstellung von Datenschutzerklärungen und Impressum. Unsere Dienstleistungen beginnen bei einem Preis von 1.000 Euro und können je nach Umfang und Komplexität des Projekts bis zu 5.000 Euro kosten. Wir legen großen Wert auf exzellenten Kundenservice und arbeiten eng mit unseren Kunden zusammen, um ihre Visionen zu verwirklichen.',
    },
  ];
  

const StyledAccordion = styled(Accordion)(({ theme }) => ({
    backgroundColor: 'rgba(255, 255, 255, 0.8)',
    color: '#333',
    marginBottom: theme.spacing(2),
    borderRadius: '25px', // Apply consistent border radius to all items
    backdropFilter: 'blur(5px)',
    '&:before': {
      display: 'none',
    },
    '&.Mui-expanded': {
      margin: theme.spacing(2, 0),
    },
  }));
  
  const StyledAccordionSummary = styled(AccordionSummary)(({ theme }) => ({
    fontSize: '1.5rem', // Larger font size for summary
    '& .MuiAccordionSummary-content': {
      margin: '12px 0',
      fontWeight: 'bold', // Make summary text bold
    },
  }));
  
  const StyledAccordionDetails = styled(AccordionDetails)(({ theme }) => ({
    fontSize: '1rem', // Smaller font size for details
    backgroundColor: 'rgba(255, 255, 255, 0.8)',
    padding: theme.spacing(2),
    color: '#555', // Slightly lighter color for details text
    borderRadius: '0 0 25px 25px', // Maintain border radius consistency
  }));
  
const FAQ = () => {
  return (
    <Container sx={{ pt: 10, pb: 10, backgroundColor: 'transparent', zIndex: -2}}>
      <Typography color="white" variant="h3" align="center" gutterBottom sx={{fontWeight: 1000}}>
        FAQ
      </Typography>
      {faqs.map((faq, index) => {
        const isFirst = index === 0;
        const isLast = index === faqs.length - 1;

        return (
          <StyledAccordion
            key={index}
            sx={{
              borderRadius: isFirst ? '25px' : isLast ? '25px' : '25px',
              '&:first-of-type': {
                borderTopLeftRadius: '25px',
                borderTopRightRadius: '25px',
              },
              '&:last-of-type': {
                borderBottomLeftRadius: '25px',
                borderBottomRightRadius: '25px',
              },
            }}
          >
            <StyledAccordionSummary expandIcon={<ExpandMoreIcon sx={{ color: '#333' }} />}>
              <Typography variant="h5" sx={{ fontWeight: 500 }}>{faq.question}</Typography>
            </StyledAccordionSummary>
            <StyledAccordionDetails>
              <Typography>{faq.answer}</Typography>
            </StyledAccordionDetails>
          </StyledAccordion>
        );
      })}
    </Container>
  );
};

export default FAQ;
