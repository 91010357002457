import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { ThemeProvider } from '@mui/material/styles';
import theme from './components/theme'; 
import NavbarComponent from './components/NavbarComponent';
import Footer from './components/Footer';
import HomePage from './components/HomePage';
import Contact from './components/Contact';
import Impressum from './components/Impressum';

function App() {
  return (
    <ThemeProvider theme={theme}>
      <Router>
        <NavbarComponent />
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/home" element={<HomePage />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/Impressum" element={<Impressum />} />
        </Routes>
        <Footer />
      </Router>
    </ThemeProvider>
  );
}

export default App;
