import React from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { Container, Box } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import { useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';

function NavbarComponent() {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <AppBar color="transparent" position="fixed" sx={{backgroundColor: 'black', zIndex: 2}}>
      <Container maxWidth="xl">
        <Toolbar disableGutters sx={{color: 'white', justifyContent: isSmallScreen ? 'center' : 'flex-start'}}>
          <img src={`${process.env.PUBLIC_URL}/images/Firmenlogo_weiss.png`} alt="Logo" style={{ width: '40px', marginRight: '10px'}} />
          <Typography variant="h4" component="div" sx={{ flexGrow: 1, display: { xs: 'none', md: 'block' } }}>
            Webagentur
          </Typography>
          <Box sx={{ display: 'flex', justifyContent: 'center', width: isSmallScreen ? '100%' : 'auto' }}>
            <Button component={RouterLink} to="/" color="inherit" sx={{ fontSize: "22px", padding: "10px 10px"}}>Home</Button>
            <Button component={RouterLink} to="/contact" color="inherit" sx={{ fontSize: "22px", padding: "10px 10px"}}>Kontakt</Button>
            <Button component={RouterLink} to="/impressum" color="inherit" sx={{ fontSize: "22px", padding: "10px 10px"}}>Impressum</Button>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
}

export default NavbarComponent;
